/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/iframe-has-title */
import { useLazyQuery, useQuery } from '@apollo/client'
import { Modal, Spin } from 'antd'
import classNames from 'classnames'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import SearchGameInput from 'src/components/SearchGameInput'
import path from 'src/constants/path'
import {
  GET_DATA_GAME,
  GET_DATA_GAME_SLOT,
  GET_PROVIDER,
  GET_PROVIDERS
} from 'src/libs/apis/graphql/queries/game.query'
import useQueryParams from 'src/libs/hooks/useQueryParams'
import { setIsLoadingPage, setIsPlayingGame } from 'src/libs/stores/common.reducer'
import { formatQueryParams } from 'src/libs/utils/utilFuncs'
import { Provider, ProviderDetailType } from 'src/types/game.type'
import './Casino.scss'
import { casinoTabs } from 'src/constants/tabLink'
import './Casino.scss'
import { isDev } from 'src/libs/utils/env'
import cn from 'classnames'

type CasinoProps = {
  isHome?: boolean
}

// type GpCodeQueryType = {
//   gpName?: string
//   gpCode?: string
// }

const CATEGORY_CASINO = 'Live Casino,Slot + Live Casino'
const TYPE_CHOOSE_CASINO = 'casino'
const DEFAULT_LIMIT = 12
const DEFAULT_OFFSET = 1

const MODE_ONLY_PROVIDERS = 'only_providers' // Mode open game in list providers
const MODE_FULL_FLOW_GAME = 'full_flow_game' // Mode open game in full flow

export default function Casino({ isHome }: CasinoProps) {
  const [mode] = useState(MODE_ONLY_PROVIDERS)
  const [isFirstLoading, setIsFirstLoading] = useState(true)
  const [query, setQuery] = useState({ limit: DEFAULT_LIMIT, offset: DEFAULT_OFFSET, total: 0 })
  const [filterData, setFilterData] = useState<Provider[]>([])
  const [gCodeCasino, setGCodeCasino] = useState('')
  const [gpCodeGame, setGpCodeGame] = useState('')
  const [urlGame, setUrlGame] = useState('')
  const [showGame, setShowGame] = useState(false)
  const [dataGame, setDataGame] = useState<ProviderDetailType[]>()
  const queryParams = useQueryParams()
  const [currentTab, setCurrenTab] = useState<string>(queryParams.tab || 'main')
  const [providers, setProviders] = useState<Provider[]>([])

  const dispatch = useDispatch()
  const navigate = useNavigate()
  // const location = useLocation()
  const buttonRef = useRef<HTMLButtonElement>(null)

  /* Query data for Casino */
  const { data: dataProviderDefault } = useQuery(GET_PROVIDERS, {
    variables: {
      limit: query.limit || DEFAULT_LIMIT,
      offset: query.offset || DEFAULT_OFFSET,
      // categories: isHome ? '' : `${CATEGORY_CASINO}`
      categories: CATEGORY_CASINO
    },
    context: { apiName: 'game-provider' },
    onError: (error) => {
      toast.error(error?.message || '문제가 발생했습니다. 나중에 다시 시도 해주십시오.')
    }
  })

  const { data, refetch, loading } = useQuery(GET_PROVIDERS, {
    variables: {
      limit: query.limit || DEFAULT_LIMIT,
      offset: query.offset || DEFAULT_OFFSET,
      categories: isHome ? '' : CATEGORY_CASINO,
      game_providers: `${queryParams?.[`filter${TYPE_CHOOSE_CASINO}`] ? queryParams?.[`filter${TYPE_CHOOSE_CASINO}`] : ''}`,
      search:
        queryParams?.[`search${TYPE_CHOOSE_CASINO}`] &&
        queryParams?.[`search${TYPE_CHOOSE_CASINO}`]?.toLowerCase() !== 'casino'
          ? queryParams?.[`search${TYPE_CHOOSE_CASINO}`]
          : ''
    },
    context: { apiName: 'game-provider' },
    onError: (error) => {
      toast.error(error?.message || '문제가 발생했습니다. 나중에 다시 시도 해주십시오.')
    }
  })

  useEffect(() => {
    if (!loading && dataProviderDefault) {
      setProviders(dataProviderDefault?.GameProviders?.data)
    }
  }, [dataProviderDefault, loading])

  const { data: dataListProviderCasino } = useQuery(GET_PROVIDER, {
    variables: {
      limit: query.limit || DEFAULT_LIMIT,
      offset: query.offset || DEFAULT_OFFSET,
      category: CATEGORY_CASINO,
      provider_code: queryParams?.providerCode,
      search:
        queryParams?.[`search${TYPE_CHOOSE_CASINO}`] &&
        queryParams?.[`search${TYPE_CHOOSE_CASINO}`]?.toLowerCase() !== 'casino'
          ? queryParams?.[`search${TYPE_CHOOSE_CASINO}`]
          : ''
    },
    context: { apiName: 'game-provider' },
    skip: mode !== MODE_FULL_FLOW_GAME,
    onError: (error) => {
      toast.error(error?.message || '문제가 발생했습니다. 나중에 다시 시도 해주십시오.')
    }
  })

  const {
    data: dataGameDetail,
    loading: loadingGameDetail,
    refetch: refetchGame
  } = useQuery(mode === MODE_FULL_FLOW_GAME ? GET_DATA_GAME_SLOT : GET_DATA_GAME, {
    variables: { provider_code: gpCodeGame, game_code: mode === MODE_FULL_FLOW_GAME ? gCodeCasino : '' },
    context: { apiName: 'game' },
    fetchPolicy: 'no-cache',
    skip: !gpCodeGame || !gCodeCasino
  })
  const [getCasinoGame] = useLazyQuery(GET_DATA_GAME, {
    context: { apiName: 'game' },
    fetchPolicy: 'no-cache'
  })
  /* End Query for Casino */

  /**
   * Function to choose a game based on the provided game codes.
   *
   * @param {string} gpCode - The game provider code
   * @param {string} [gCode] - Optional game code
   * @return {void} Returns nothing
   */
  const chooseGame = async (gpCode: string, gCode?: string): Promise<void> => {
    if (mode === MODE_FULL_FLOW_GAME && !queryParams?.providerCode) {
      const newQueryParams = { ...queryParams, providerCode: gpCode }
      navigate(location.pathname + formatQueryParams(newQueryParams))

      return
    }

    if (!gCode) {
      const { data, error } = await getCasinoGame({
        variables: {
          provider_code: gpCode
        },
        onCompleted(data) {
          if (window.innerWidth < 768) {
            window.open(data?.GameQuery?.url, '_blank')
          }
        }
      })
      if (error) {
        toast.error(error.message)
        setUrlGame('')
        setGpCodeGame('')
      } else {
        if (window.innerWidth < 768) return
        setShowGame(true)
        dispatch(setIsPlayingGame(true))
        setUrlGame(data?.GameQuery?.url || '')
        setGpCodeGame(gpCode)
      }
      return
    }

    // setGpCodeGame(gpCode)

    // if (mode === MODE_FULL_FLOW_GAME && gCode) {
    //   setGCodeCasino(gCode)
    // } else {
    //   setGCodeCasino('')
    // }
  }

  /* Handle load more data when scroll */
  const loadMoreData = useCallback(() => {
    setQuery({ ...query, limit: query.limit + DEFAULT_LIMIT })
    refetch()
  }, [query, dataListProviderCasino])

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) loadMoreData()
      })
    })

    if (buttonRef.current) {
      observer.observe(buttonRef.current)
    }

    return () => {
      if (buttonRef.current) observer.unobserve(buttonRef.current)
    }
  }, [loadMoreData])
  /* End handle load more data when scroll */

  useEffect(() => {
    if (queryParams?.[`filter${TYPE_CHOOSE_CASINO}`] || queryParams?.[`search${TYPE_CHOOSE_CASINO}`]) refetch()
  }, [queryParams, queryParams.filter, queryParams.search, refetch])

  useEffect(() => {
    if (!isFirstLoading && (loading || loadingGameDetail)) {
      setIsFirstLoading(true)
      dispatch(setIsLoadingPage(true))
    }

    return () => {
      dispatch(setIsLoadingPage(false))
    }
  }, [dispatch, loading, loadingGameDetail])

  useEffect(() => {
    if (
      dataProviderDefault &&
      dataProviderDefault?.GameProviders &&
      dataProviderDefault?.GameProviders?.data?.length > 0
    ) {
      setFilterData(() => {
        return dataProviderDefault?.GameProviders?.data?.map((item: Provider) => {
          return {
            gpCode: item?.gpCode,
            gpName: item?.gpName
          }
        })
      })
    }
  }, [dataProviderDefault])

  useEffect(() => {
    if (dataGameDetail && !loadingGameDetail && !dataGameDetail?.GameQuery && !dataGameDetail?.GameQuery?.url) {
      toast.error('게임을 로드하는 중 오류가 발생했습니다.')

      setTimeout(() => {
        setShowGame(false)
      }, 1000)
    }
  }, [dataGameDetail, dataGameDetail?.GameQuery, loadingGameDetail])

  useEffect(() => {
    if (
      dataListProviderCasino ||
      dataListProviderCasino?.GameProvider?.data ||
      dataListProviderCasino?.GameProvider?.data?.length > 0
    ) {
      setDataGame(dataListProviderCasino?.GameProvider?.data)
    }
  }, [dataListProviderCasino])

  /* Scroll to top when the page changes */
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const handleChangeTab = (tab: string) => {
    setCurrenTab(tab)
    navigate({
      pathname: path.casino,
      search: '?' + `tab=${tab}`
    })
  }

  const fetchMoreCasinoGames = () => {
    setQuery({ ...query, limit: query.limit + DEFAULT_LIMIT })
  }

  useEffect(() => {
    refetch({
      variables: {
        limit: query.limit + DEFAULT_LIMIT,
        offset: query.offset,
        categories: CATEGORY_CASINO,
        game_providers: queryParams?.filter || '',
        search: queryParams?.search || ''
      }
    })
  }, [query, queryParams?.filter, queryParams?.search, refetch])

  useEffect(() => {
    if (!queryParams.tab) {
      setCurrenTab('main')
    }
  }, [queryParams.tab])

  return (
    <div
      className={classNames('px-5', {
        ['lg:max-h-[calc(100dvh-150px)] overflow-y-auto sidebar__overflow']: !isHome
      })}
      data-aos='fade-up'
    >
      {(currentTab === 'live' || currentTab === 'main') && (
        <div className='aspect-[1300/355]'>
          <img src='/banner-casino.webp' className='w-full h-full object-cover' alt='' />
        </div>
      )}
      {currentTab === 'slot' && (
        <div className='aspect-[1300/355]'>
          <img src='/banner-slot.png' className='w-full h-full object-cover' alt='' />
        </div>
      )}

      {/* {data && data?.GameProviders && data?.GameProviders?.data?.length > 0 ? (
        <Pagination
          className={`mx-auto block w-max pb-10 mt-0 ${styles['pagination-casino']}`}
          pageSize={data?.GameProviders?.per_page ?? 10}
          onChange={(page) => {
            navigate(
              location.pathname +
                formatQueryParams({ ...queryParams, offset: page, limit: data?.GameProviders?.per_page ?? 10 })
            )
          }}
          current={Number(queryParams?.offset) ?? 1}
          total={data?.GameProviders?.total ?? 1}
        />
      ) : null} */}
      <div className='flex gap-6 py-6 mt-4 overflow-x-auto pe-4 w-full'>
        {casinoTabs.map((tab, index) => (
          <button
            className={classNames('inline-flex gap-3 items-center py-3 px-4 rounded-3xl flex-shrink-0', {
              'bg-primary bg-opacity-35 text-primary': currentTab === tab.link,
              'bg-primary bg-opacity-5': currentTab !== tab.link
            })}
            onClick={() => {
              handleChangeTab(tab.link)
            }}
            key={index}
          >
            <img
              src={currentTab === tab.link ? tab.icon_active : tab?.icon}
              alt={tab?.name}
              title={tab?.name}
              width={16}
              height={16}
            />
            <span className='text-12 text-nowrap'>{tab.name}</span>
          </button>
        ))}
      </div>
      {(currentTab === 'main' || currentTab === 'live') && (
        <>
          <div className='bg-[#0b0d10] p-4 rounded-md font-semibold my-4 flex  items-center flex-wrap'>
            <div className='flex-auto mb-2 lg:mb-0'>라이브 카지노</div>
            <div className='filter flex-wrap'>
              <SearchGameInput
                providers={filterData}
                defaultValue={queryParams?.[`search${TYPE_CHOOSE_CASINO}`]}
                refetch={refetch}
                typeParams={TYPE_CHOOSE_CASINO}
                defaultValueFilter={queryParams?.[`filter${TYPE_CHOOSE_CASINO}`]}
              />
            </div>
          </div>
          {!queryParams?.providerCode && (
            <>
              {providers?.length === 0 && (
                <div className='py-10'>
                  <p className='text-center text-18 font-bold pb-4'>데이터가 없습니다</p>
                </div>
              )}

              {providers?.length > 0 && (
                <>
                  <div className='grid grid-cols-2 gap-2 md:grid-cols-4'>
                    {providers?.map((provider: Provider, index: number) => {
                      const isMaintain = (provider?.gpMaintenance && JSON.parse(provider?.gpMaintenance)) || ''
                      const gpImgBackground = isDev
                        ? '/bg_slot_provider_default.png'
                        : (provider?.gpImgBackground && JSON.parse(provider?.gpImgBackground)?.casino) || ''
                      const gpImage = (provider?.gpImage && JSON.parse(provider?.gpImage)?.casino) || ''
                      const gpLogo = (provider?.gpLogo && JSON.parse(provider?.gpLogo)?.casino) || ''

                      return (
                        <button
                          onClick={() => {
                            console.log(isMaintain?.casino?.enable, provider?.gpCode)
                            if (isMaintain?.casino?.enable) return

                            chooseGame(provider.gpCode ?? '')
                          }}
                          key={provider?.gpCode}
                          className='flex flex-col gap-2 group'
                        >
                          <div className='w-full overflow-hidden duration-150 relative border border-primary group-hover:border-primary aspect-[1.4]'>
                            <div className='relative w-full h-full overflow-hidden'>
                              <img
                                src={gpImgBackground}
                                alt=''
                                className='w-full h-full'
                                // className='w-full h-full group-hover:blur-sm'
                                onError={(e) => {
                                  e.currentTarget.src = '/default-bg-provider.png'
                                  e.currentTarget.onerror = null
                                }}
                              />
                              <div className='absolute top-0 left-0'>
                                <img src={gpLogo} alt='' className='w-3/4 md:w-fit max-w-[160px] max-h-[60px]' />
                              </div>
                              <div className='absolute bottom-0 right-0 h-full'>
                                <img
                                  src={gpImage}
                                  alt={provider?.gpName}
                                  title={provider?.gpName}
                                  className='w-full max-w-[170px] h-full object-contain'
                                  onError={(e) => {
                                    e.currentTarget.src = '/default_casino_character.png'
                                    e.currentTarget.onerror = null
                                  }}
                                />
                              </div>
                              <div className='flex flex-col items-start left-2 absolute bottom-4 w-full'>
                                <span className='text-16 md:text-20 font-black neonText mb-2'>{provider?.gpName}</span>
                                <span className='bg-primary rounded-full px-4 uppercase text-12 md:text-14'>
                                  {provider?.gpNameEn}
                                </span>
                              </div>
                              {/* <div className='absolute bottom-0 left-0 w-full h-full duration-150 flex flex-col justify-end items-center'>
                              <img
                                src={gpImage}
                                alt={provider?.gpName}
                                title={provider?.gpName}
                                width='310'
                                height='282'
                                className='product__image w-fit duration-150 transform-ani-child'
                                onError={(e) => {
                                  e.currentTarget.src = '/casino_default.png'
                                  e.currentTarget.onerror = null
                                }}
                              />
                            </div> */}
                              {isMaintain?.casino?.enable && (
                                <div
                                  className='absolute top-0 left-0 w-full h-full z-[3] pointer-events-none'
                                  style={{
                                    background: `rgba(0,0,0,0.8) url(/assets/images/casino/main-taincheck.png) center center no-repeat`,
                                    backgroundSize: 'contain'
                                  }}
                                ></div>
                              )}
                            </div>
                          </div>
                          {/* <span className='w-full py-2 text-center text-12 bg-black-1 text-white rounded-md duration-150 group-hover:bg-primary group-hover:text-content'>
                          {provider?.gpName}
                        </span> */}
                        </button>
                      )
                    })}
                  </div>
                  {data?.GameProviders?.has_more_pages && (
                    <button
                      onClick={fetchMoreCasinoGames}
                      className='h-10 hover:bg-primary font-black hover:text-white hover:border-primary rounded bg-primary text-white mt-5 w-fit border-primary px-4 min-w-20 text-14 flex justify-center items-center gap-2 mx-auto'
                    >
                      LOAD MORE
                    </button>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}

      {(currentTab === 'main' || currentTab === 'sport') && queryParams?.providerCode && (
        <>
          {dataGame?.length === 0 ? (
            <div className='py-10'>
              <p className='text-center text-18 font-bold pb-4'>데이터가 없습니다</p>
              <Link
                to={path.casino}
                title='Casino'
                className='h-10 bg-primary rounded px-4 mx-auto flex justify-center items-center w-fit text-content font-bold'
              >
                카지노로 돌아가기
              </Link>
            </div>
          ) : (
            <div className='mt-2 game-container pb-14 grid grid-cols-2 lg:grid-cols-4 gap-4 lg:gap-8 px-2 md:px-0'>
              {dataGame?.map((provider: ProviderDetailType, index: number) => {
                return (
                  <div
                    key={index}
                    onClick={() => {
                      chooseGame(provider?.gpCode ?? '', provider?.gCode)
                    }}
                    className='cursor-pointer rounded-lg overflow-hidden group duration-150'
                  >
                    <div className='relative w-full pb-[72%] overflow-hidden mb-3'>
                      <img
                        src={
                          provider?.gIconUrl
                            ? provider?.gIconUrl
                            : 'https://apitest.spobulls.net/uploads/2024/04/images/casino/662639433ea43.png'
                        }
                        alt={provider?.gNameEn}
                        title={provider?.gNameEn}
                        width='310'
                        height='282'
                        className='product__image rounded-lg object-cover absolute top-0 left-0 w-full h-full bg-black-1 hover:border-2 hover:border-primary'
                      />
                    </div>
                    <section className='rounded-md product__content bg-secondary-2 text-white text-12 flex flex-col justify-center items-center py-2 hover:bg-primary group-hover:text-content transition-all group-hover:bg-primary'>
                      <h3 className='product__title'>{provider?.gName}</h3>
                    </section>
                  </div>
                )
              })}
              {Math.ceil(
                Number(dataListProviderCasino?.GameProvider?.total) /
                  Number(dataListProviderCasino?.GameProvider?.per_page)
              ) > 1 && <button onClick={loadMoreData} ref={buttonRef}></button>}
            </div>
          )}
        </>
      )}

      {/* {dataListProviderCasino &&
      dataListProviderCasino?.GameProvider &&
      dataListProviderCasino?.GameProvider?.data?.length > 0 ? (
        <Pagination
          className={`mx-auto block w-max pb-10 mt-0 pagination-casino`}
          pageSize={dataListProviderCasino?.GameProvider?.per_page ?? DEFAULT_LIMIT}
          onChange={(page) => {
            navigate(
              location.pathname +
                formatQueryParams({
                  ...queryParams,
                  offset: page,
                  limit: dataListProviderCasino?.GameProvider?.per_page ?? DEFAULT_LIMIT
                })
            )
          }}
          current={Number(queryParams?.offset) ?? DEFAULT_OFFSET}
          total={dataListProviderCasino?.GameProvider?.total ?? 1}
        />
      ) : null} */}

      <Modal
        open={showGame}
        destroyOnClose
        centered
        className='!w-screen !h-screen max-w-none m-0 p-0 top-0 left-0'
        closeIcon={null}
        footer={null}
        classNames={{
          body: 'bg-black h-full w-full',
          content: '!bg-transparent w-full h-screen !p-2 md:!p-10',
          wrapper: 'w-screen h-screen'
        }}
      >
        <div className='w-full h-full relative'>
          {urlGame ? (
            <iframe
              src={urlGame}
              frameBorder='0'
              allowFullScreen
              className={cn('w-full h-full', {
                'max-h-[calc(100dvh-50px)]': gpCodeGame !== 'Evolution'
              })}
              // style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, width: '100%', height: '100%' }}
            ></iframe>
          ) : null}
          {loadingGameDetail && (
            <Spin size='large' className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2' />
          )}
          <img
            className='absolute top-0 right-4 mt-[21px] z-10 cursor-pointer'
            src='/assets/images/icons/close-auth-modal.png'
            alt='close-modal'
            title='close'
            width='20'
            height='20'
            onClick={() => {
              setShowGame(false)
              setUrlGame('')
              dispatch(setIsPlayingGame(false))
            }}
          />
        </div>
      </Modal>
    </div>
  )
}
